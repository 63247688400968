











































































































































































































































    import { Component, Vue, Prop } from 'vue-property-decorator';
    import AppContainerBase from '@/components/util/AppContainerBase.vue';
    import { internet } from '@/utils/Internet';
    import { IDataTable } from '@/model/main/IDataTable';
    import Util from '@/utils/Util';
    import { Icon } from '@/model/util/Icon';
    @Component({
		name: 'MainDetailLibrary',
		components: {
			AppContainerBase,
		},
	})
	export default class MainDetailLibrary extends Vue {
        @Prop({
			type: String,
			required: true,
		})
		readonly filtroFechaInicial!: string;
        @Prop({
			type: String,
			required: true,
		})
		readonly filtroFechaFinal!: string;
        //i18n
        public titulo = this.$t("BibliotecaAudioSearch.titulo");
        public btn_cerrar = this.$t("DataTableSelection.cerrar");
        public btn_continuar = this.$t("DataTableSelection.continuar");
        public texto_confirmacion = this.$t("DataTableSelection.confirmacion");
        public texto_detalle = this.$t("BibliotecaAudioSearch.detalleAudio");
        public btn_transcribir = this.$t("DataTableSelection.transcribir");
        public btn_volver = this.$t("BibliotecaAudioSearch.volver");
        public noDataText = this.$t("DataTable.noDataText");
        public elementPerPage = this.$t("DataTable.elementPerPage");
		public countElementPage = this.$t("DataTable.countElementPage");
        public searchText = this.$t("DataTable.searchText");
        public texto_call_center = this.$t("DataTableSelection.callCenter");
        public texto_campania = this.$t("DataTableSelection.campania");
        public texto_agente = this.$t("DataTableSelection.agente");
        public texto_cantidad_audios = this.$t("DataTableSelection.cantidadAudios");
        public texto_acciones = this.$t("DataTableSelection.acciones");
        public texto_rut = this.$t("DataTableSelection.rut");
        public texto_fecha = this.$t("DataTableSelection.fecha");
        public texto_nombreAudio = this.$t("DataTableSelection.nombreAudio");
        public singleSelect = false;
        public filtro_transcript: Array<string> = [];
        public total_transcript = 0;
        public dialog_seleccionados = false;
        public texto_atencion = this.$t("DataTableSelection.atencion");
        public dialog_analisis = false; 
        public btnAnalizar = this.$t("audioDetail.btnAnalizar");
        public cardTitleAnalisis = this.$t("audioDetail.cardTitleAnalisis");
        public selectScript = this.$t("audioDetail.selectScript");
        public subtiSelectScript = this.$t("audioDetail.subtiSelectScript");
        public cantidadINTaudios = this.$t("audioDetail.cantidadINTaudios");
        public cantidadFloatAudios = this.$t("audioDetail.cantidadFloatAudios");
        public selecCantidad = this.$t("audioDetail.selecCantidad");
        public selecCantidadPor = this.$t("audioDetail.selecCantidadPor");
        public script_selected = "";
        public selectTiLlamado = this.$t("audioDetail.selectTiLlamado");
        public subtiSelectTiLlamado = this.$t("audioDetail.subtiSelectTiLlamado");
        public btnCerrar = this.$t("audioDetail.btnCerrar");
        public btnContinuar = this.$t("audioDetail.btnContinuar");
        public dialog_progress = false; 
        public dialogProgress = this.$t("MainTags.dialogProgress");
        public tipo_llamada: Array<string> = [];
        public script: Array<string> = [];
        public scriptsTipoLlamada: any = {};
        public analizado = "";
        public transcripcion = "";
        public category_weight_value = 0;
        public category_weight_value_porc = 0;

        public radioCantidadAudios = false;
        // aqui se deberia ir formando la lista de los seleccionados "selected"
        public selected : Array<any> = [];
        public fechaInicial = "";
        public fechaFinal = "";
        public callCenter = "";
        public rutaSftp = "";
        public campania = "";
        public agente = "";
        public tipo = "";
        public valor = "";
        public dialogPorcentaje = false;
        public rows: Array<IDataTable> = []
        public search = '';
        public tipo_llamada_selected = "";
        public isLoading = false;
        public headers = [
            //{text: this.texto_call_center as string, value: "Call_center"}, 
            {text: this.texto_campania as string, value: "Campania"}, 
            {text: this.texto_agente as string, value: "Agente"}, 
            {text: 'Tipo Llamada', value: "Tipo_llamada"}, 
            // {text: this.texto_rut as string, value: "Rut"}, 
            {text: this.texto_fecha as string, value: "Fecha"}, 
            {text: this.texto_nombreAudio as string, value: "Nombre_audio"}, 
            {text: 'Estado', value: 'Estado'},
            // {text: this.texto_acciones as string, value: "Accion"}
        ]

        public disable_transcript = true;
        public ejecuciones_seleccionadas: Array<any> = [];

        mounted() {
            this.fechaInicial = this.$route.params.fechaInicial;
            this.fechaFinal = this.$route.params.fechaFinal;
            if (this.$route.params.destinatario === "true") {
                this.tipo = this.$route.params.tipo;
                this.valor = this.$route.params.valor;
                let params = `fechaInicial=${this.fechaInicial}&fechaFinal=${this.fechaFinal}&${this.tipo}=${this.valor}`;
                this.getData("getAudiosDestinatario", params);
            } else {
                this.callCenter = this.$route.params.callCenter;
                this.rutaSftp = this.$route.params.rutaSftp;
                // this.campania = this.$route.params.campania;
                // this.agente = this.$route.params.agente;
                // let params = `fechaInicial=${this.fechaInicial}&fechaFinal=${this.fechaFinal}&callCenter=${this.callCenter}&campania=${this.campania}&agente=${this.agente}`;
                let params = `fechaInicial=${this.fechaInicial}&fechaFinal=${this.fechaFinal}&callCenter=${this.callCenter}&ruta=${this.rutaSftp}`;
                this.getData("getAudiosNuevo", params);
            }
            //this.obtenerScripts();
            // this.obtenerTranscripcion();
        }

        private getData(request: string, params: string) {
            this.isLoading = true;
            let num = 0;
            internet
                .newRequest()
                .get(`biblioteca/${request}?${params}`)
                .then((response) => {
                    //console.log(response.data);
                    let dataTable: Array<IDataTable> = [];
                    for (let item of response.data) {
                        const row: IDataTable = {};
                        row['Item_key'] = `fila${num}`;
                        // row['Call_center'] = item.Call_center;
                        row['Campania'] = item.Campania;
                        row['Agente'] = item.Agente;
                        // row['Rut'] = item.Rut_cliente;
                        row['Fecha'] = item.Fecha;
                        row['Nombre_audio'] = item.Nombre_audio;
                        row['Ruta_audio'] = item.Ruta_audio;
                        row['Tipo_llamada'] = item.Tipo_llamada;
                        row['Estado'] = item.Estado;
                        // row['Accion'] = "";
                        num +=1;
                        if (!this.tipo_llamada.includes(item.Tipo_llamada)) {
                            this.tipo_llamada.push(item.Tipo_llamada);
                            this.ejecuciones_seleccionadas.push({tipo_llamada: item.Tipo_llamada, cantidad: 0})
                        }
                        dataTable.push(row);
                    }
                    this.rows = dataTable;
                    console.log(this.rows);
                })
                .catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
                
        }
        
        public detalle(payload: any) {
            console.log("DETALLE", payload);
            this.$router.push({
				name: 'MainAudioDetail',
                params: {
                    ruta_audio: `${payload["Ruta_audio"]}/${payload["Nombre_audio"]}`,
                    nombre_audio: payload["Nombre_audio"],
                    nombre_agente: payload["Agente"]
                }
            });
        }

        /*public pre_transcribir() {
            let total = 0;
            let filtro: Array<string> = [];
                console.log('selected', this.selected)
            for (let item of this.selected) {
                total += 1//item["Total_audios"];
                filtro.push(`${item["Nombre_audio"]}`);
            }
            if (total > 0) {
                this.filtro_transcript = filtro;
                this.total_transcript = total;
                this.dialog_transcrip = true;
            } else {
                console.log('No se seleccionaron audios');
                this.dialogPorcentaje = true;
            }
        }*/

        public transcribir() {
            let params = `fechaInicial=${this.filtroFechaInicial}&fechaFinal=${this.filtroFechaFinal}`
            let mensaje = Util.replaceTextWith(this.$t("DataTableSelection.mensaje") as string, "%s", this.total_transcript.toString())
            this.selected = [];
            //this.cc_transcript = [];
            //this.cp_transcript = [];
            //this.ag_transcript = [];
            
            this.disable_transcript = false;
            this.total_transcript = 0;
            this.dialog_seleccionados = false;
            Util.showMessage(
                mensaje,
                Icon.SUCCESS
            );
            internet
                .newRequest()
                .post(`biblioteca/transcribirAudiosPorNombre?${params}`, {'lista': this.filtro_transcript})
                .then((response) => {
                    //console.log(response.data);
                    this.disable_transcript = true;
                    this.filtro_transcript = [];
                })
                
        }

        /*private obtenerTranscripcion() {
			this.isLoading = true;
			internet
                .newRequest()
                .get(`biblioteca/transcripcion?nombre_audio=${this.audio}`)
                .then((response) => {
					//console.log(response.data);
					this.analizado = response.data.analizado ? "Si" : "No";
					if (response.data.script != "") {
						this.script_selected = response.data.script;
					}
					if (response.data.tipo_llamada != "") {
						this.tipo_llamada = this.scriptsTipoLlamada[this.script_selected];
						this.tipo_llamada_selected = response.data.tipo_llamada;
					}
					if (response.data.mensaje == "Transcripcion encontrada") {
						this.transcripcion = response.data.transcripcion;
					} else {
						this.transcripcion = response.data.mensaje;
					}
                })
				.catch(() => {
					console.log("Error al cargar la transcripcion")
				})
				.finally(() => {
					this.isLoading = false;
				});		
		}*/

        public abrirDialogAnalisis() {
            if (this.selected.length > 0) {
                console.log(this.selected);
                this.dialog_seleccionados = true;
            } else {
                this.dialog_analisis=true;
            }
        }

        public analizar() {
			this.dialog_analisis = false;
			//this.dialog_progress = true;
            let params:Array<any> = [];
            for (let item of this.ejecuciones_seleccionadas) {
                if (this.radioCantidadAudios) {
                    params.push({
                        query: {
                            AudioRuta: this.rutaSftp,
                            MT_TipoLLamadaNombre: item.tipo_llamada,
                        },
                        numero_audios: item.cantidad,
                        porcentaje_audios: null
                    })
                } else {
                    params.push({
                        query: {
                            AudioRuta: this.rutaSftp,
                            MT_TipoLLamadaNombre: item.tipo_llamada,
                        },
                        numero_audios: null,
                        porcentaje_audios: item.cantidad
                    })
                }
            }
            console.log(params);
            internet
                .newRequest()
                .post(`biblioteca/ejecutarSeleccionados`, {"lista": params})
                .then((response) => {
					console.log(response.data);
					//this.dialog_progress = false;
					Util.showMessage(
						'Audios analizados con exito',
						Icon.SUCCESS
					);
                })
				.catch(() => {
					console.log("Error al analizar los audio");
					this.dialog_progress = false;
					Util.showMessage(
						"Error al analizar los audio", 
						Icon.ERROR
					);
				})
			// internet
            //     .newRequest()
            //     .post(`biblioteca/analizar?nombre_audio=${this.audio}&nombre_script=${this.script_selected}&tipo_llamada=${this.tipo_llamada_selected}`)
            //     .then((response) => {
			// 		//console.log(response.data);
			// 		this.analizado = "Si";
			// 		if (response.data.mensaje == "Audio transcrito y analizado correctamente") {
			// 			this.transcripcion = response.data.transcripcion;
			// 		}
			// 		this.dialog_progress = false;
			// 		Util.showMessage(
			// 			'Audio analizado con exito',
			// 			Icon.SUCCESS
			// 		);
            //     })
			// 	.catch(() => {
			// 		console.log("Error al analizar el audio");
			// 		this.dialog_progress = false;
			// 		Util.showMessage(
			// 			"Error al analizar el audio", 
			// 			Icon.ERROR
			// 		);
			// 	})
		}

        public analizarSelected() {
            let audios:Array<string> = [];
            for (let item of this.selected) {
                audios.push(item.Nombre_audio);
            }
            console.log({"query": {AudioRuta: this.rutaSftp, AudioNombre: {"$in": audios}}});
            // internet
            //     .newRequest()
            //     .post(`biblioteca/ejecutarAudiosSeleccionados`, {"query": {AudioRuta: this.rutaSftp, AudioNombre: {"$in": audios}}})
            //     .then((response) => {
			// 		console.log(response.data);
			// 		//this.dialog_progress = false;
			// 		Util.showMessage(
			// 			'Audios analizados con exito',
			// 			Icon.SUCCESS
			// 		);
            //     })
			// 	.catch(() => {
			// 		console.log("Error al analizar los audio");
			// 		this.dialog_progress = false;
			// 		Util.showMessage(
			// 			"Error al analizar los audio", 
			// 			Icon.ERROR
			// 		);
			// 	})
        }

        public back(){
            this.$router.push({
				name: 'MainAudioSearch',
			});
        }

        public getTextCountElementPerPage(
			to: number,
			end: number
		): string {
			let text = Util.replaceTextWith(
				this.countElementPage as string,
				'$1',
				to.toString()
			);
			text = Util.replaceTextWith(
				text,
				'$2',
				end.toString()
			);
			return text;
		}
        filterByText(
			value: string,
			search: string,
			item: IDataTable
		) {
			return (
				value != null &&
				search != null &&
				value.toString().indexOf(search) !== -1
			);
        }

        private obtenerScripts() {
			internet
            .newRequest()
            .get("biblioteca/getScriptsTipoLlam")
            .then((response) => {
                //console.log(response.data);
                this.scriptsTipoLlamada = response.data;
                this.script = Object.keys(this.scriptsTipoLlamada) as Array<string>;
                })
                .catch(() => {
                    console.log("Error al cargar los scripts");
				})
            }

        public changeScript() {
			this.tipo_llamada = this.scriptsTipoLlamada[this.script_selected];
		}

        public reemplazarTexto(texto:string, patron:string, agregado: string) {
            return Util.replaceTextWith(texto, patron, agregado);
        }

    }
